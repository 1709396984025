import React, { useState, useEffect } from 'react';

const GuidGenerator: React.FC = () => {
    const [guid, setGuid] = useState<string>('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx');
    const [version, setVersion] = useState<string>('4');
    const [showNotification, setShowNotification] = useState<boolean>(false);

    // Generate GUID on component mount
    useEffect(() => {
        generateGuid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showNotification) {
            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [showNotification]);

    const generateGuid = () => {
        let result = '';

        switch (version) {
            case '1':
                // Version 1 (time-based)
                result = 'xxxxxxxx-xxxx-1xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, () => {
                    return Math.floor(Math.random() * 16).toString(16);
                });
                break;
            case '3':
                // Version 3 (namespace with MD5)
                result = 'xxxxxxxx-xxxx-3xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, () => {
                    return Math.floor(Math.random() * 16).toString(16);
                });
                break;
            case '4':
                // Version 4 (random)
                result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                    const r = Math.random() * 16 | 0;
                    const v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
                break;
            case '5':
                // Version 5 (namespace with SHA-1)
                result = 'xxxxxxxx-xxxx-5xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, () => {
                    return Math.floor(Math.random() * 16).toString(16);
                });
                break;
            default:
                // Default to version 4
                result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                    const r = Math.random() * 16 | 0;
                    const v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        }

        setGuid(result);

        // Copy to clipboard after generating
        navigator.clipboard.writeText(result)
            .then(() => {
                setShowNotification(true);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const copyToClipboard = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent event bubbling
        navigator.clipboard.writeText(guid)
            .then(() => {
                setShowNotification(true);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <div className="tool-card">
            <h3>GUID Generator</h3>
            <div className="tool-content">
                <div className="guid-result" onClick={copyToClipboard} title="Click to copy">
                    <code>{guid}</code>
                    <button className="copy-button" onClick={copyToClipboard}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                        </svg>
                    </button>
                </div>
                <div className="guid-actions">
                    <div className="version-selector">
                        <label htmlFor="guid-version">Version:</label>
                        <select
                            id="guid-version"
                            value={version}
                            onChange={(e) => {
                                setVersion(e.target.value);
                                // Generate new GUID when version changes
                                setTimeout(() => generateGuid(), 0);
                            }}
                        >
                            <option value="1">UUID v1 (time-based)</option>
                            <option value="3">UUID v3 (namespace, MD5)</option>
                            <option value="4">UUID v4 (random)</option>
                            <option value="5">UUID v5 (namespace, SHA-1)</option>
                        </select>
                    </div>
                    <button className="action-button" onClick={generateGuid}>
                        Generate GUID
                    </button>
                </div>
                {showNotification && (
                    <div className="notification-message">
                        GUID copied to clipboard!
                    </div>
                )}
            </div>
        </div>
    );
};

export default GuidGenerator;