import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import TabNavigation from './components/TabNavigation';
import ConverterSection from './components/ConverterSection';
import ProgrammerSection from './components/ProgrammerSection';
import TranslateSection from './components/TranslateSection';
import AdBanner from './components/AdBanner';

function App() {
  const [activeSection, setActiveSection] = useState('converter');

  return (
    <div className="App">
      {/* Sol taraf reklam */}
      <AdBanner position="left" adSlot="1234567890" />

      <div className="container">
        <Header />
        <TabNavigation activeSection={activeSection} setActiveSection={setActiveSection} />

        <main>
          {activeSection === 'converter' && <ConverterSection />}
          {activeSection === 'programmer' && <ProgrammerSection />}
          {activeSection === 'translate' && <TranslateSection />}
        </main>

        <Footer />
      </div>

      {/* Sağ taraf reklam */}
      <AdBanner position="right" adSlot="0987654321" />
    </div>
  );
}

export default App;
