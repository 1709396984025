import React, { useState, useEffect, useCallback } from 'react';
import { convertUnits, CONVERTER_SECTIONS } from '../services/converterService';

interface ConverterCardProps {
    title: string;
    options: string[];
    resultUnit: string;
    sectionId: number;
}

const ConverterCard: React.FC<ConverterCardProps> = ({ title, options, resultUnit, sectionId }) => {
    const [value, setValue] = useState<number>(0);
    const [fromUnit, setFromUnit] = useState<string>(options[0]);
    const [toUnit, setToUnit] = useState<string>(options[0]);
    const [result, setResult] = useState<number>(0);
    const [displayUnit, setDisplayUnit] = useState<string>(resultUnit);
    const [isCalculating, setIsCalculating] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const calculateConversion = useCallback(async () => {
        try {
            if (fromUnit === toUnit) {
                setResult(value);
                setDisplayUnit(toUnit.toLowerCase());
                setIsCalculating(false);
                return;
            }

            const response = await convertUnits(
                sectionId,
                fromUnit,
                toUnit,
                value
            );

            setResult(parseFloat(response.result.toString()));
            setDisplayUnit(toUnit.toLowerCase());
        } catch (err) {
            console.error('Conversion error:', err);
            setError('An error occurred during conversion. Please try again.');
        } finally {
            setIsCalculating(false);
        }
    }, [value, fromUnit, toUnit, sectionId]);

    // Calculate conversion on mount and when dependencies change
    useEffect(() => {
        // Show calculating animation
        setIsCalculating(true);
        setError(null);

        // Debounce calculation to show animation
        const timer = setTimeout(() => {
            calculateConversion();
        }, 300);

        return () => clearTimeout(timer);
    }, [calculateConversion]);

    const swapUnits = () => {
        // Swap the from and to units
        const temp = fromUnit;
        setFromUnit(toUnit);
        setToUnit(temp);
    };

    return (
        <div className="tool-card">
            <h3>{title}</h3>
            <div className="tool-inputs">
                <div className="input-group">
                    <label>Value</label>
                    <input
                        type="number"
                        placeholder="Value"
                        value={value || ''}
                        onChange={(e) => setValue(parseFloat(e.target.value) || 0)}
                    />
                </div>

                <div className="select-group">
                    <label>Unit</label>
                    <div className="unit-conversion-container">
                        <select
                            value={fromUnit}
                            onChange={(e) => setFromUnit(e.target.value)}
                        >
                            {options.map((option, index) => (
                                <option key={index}>{option}</option>
                            ))}
                        </select>
                        <button
                            className="swap-button"
                            onClick={swapUnits}
                            title="Birimleri değiştir"
                        >
                            <span className="material-symbols-outlined">swap_horiz</span>
                        </button>
                        <select
                            value={toUnit}
                            onChange={(e) => setToUnit(e.target.value)}
                        >
                            {options.map((option, index) => (
                                <option key={index}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className={`tool-result ${isCalculating ? 'calculating' : ''}`}>
                {error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <p>
                        <span className="result-label">Result:</span>
                        <span className="result-value">{result}</span>
                        <span className="result-unit">{displayUnit}</span>
                    </p>
                )}
            </div>
        </div>
    );
};

export default ConverterCard; 