import React from 'react';
import ConverterCard from './ConverterCard';
import { CONVERTER_SECTIONS } from '../services/converterService';

const ConverterSection: React.FC = () => {
    // Converter options
    const lengthOptions = ['m', 'km', 'cm', 'mm', 'in', 'ft'];
    const weightOptions = ['kg', 'gr', 'mg', 'tons', 'lb', 'oz'];
    const temperatureOptions = ['C', 'F', 'K'];
    const areaOptions = ['m2', 'dm2', 'cm2', 'mm2', 'km2', 'ha', 'a', 'ac', 'in2', 'ft2', 'mi2'];
    const volumeOptions = ['m3', 'dm3', 'cm3', 'mm3', 'l', 'ml', 'gal'];
    const timeOptions = ['sec', 'min', 'h', 'ms', 'us', 'ns'];

    return (
        <section className="section-content">
            <h2>Converter Tools</h2>
            <p className="section-description">
                Quickly and easily convert between different units. Enter a value, select source and target units.
            </p>
            debugger;
            <div className="tools-grid">
                <ConverterCard
                    title="Length Converter"
                    options={lengthOptions}
                    resultUnit="m"
                    sectionId={CONVERTER_SECTIONS.LENGTH}
                />

                <ConverterCard
                    title="Weight Converter"
                    options={weightOptions}
                    resultUnit="kilogram"
                    sectionId={CONVERTER_SECTIONS.WEIGHT}
                />

                <ConverterCard
                    title="Temperature Converter"
                    options={temperatureOptions}
                    resultUnit="°C"
                    sectionId={CONVERTER_SECTIONS.TEMPERATURE}
                />

                <ConverterCard
                    title="Area Converter"
                    options={areaOptions}
                    resultUnit="square meter"
                    sectionId={CONVERTER_SECTIONS.AREA}
                />

                <ConverterCard
                    title="Volume Converter"
                    options={volumeOptions}
                    resultUnit="liter"
                    sectionId={CONVERTER_SECTIONS.VOLUME}
                />

                <ConverterCard
                    title="Time Converter"
                    options={timeOptions}
                    resultUnit="sec"
                    sectionId={CONVERTER_SECTIONS.TIME}
                />
            </div>
        </section>
    );
};

export default ConverterSection; 