import React from 'react';
import GuidGenerator from './GuidGenerator';

const ProgrammerSection: React.FC = () => {
    return (
        <section className="section-content">
            <h2>Programmer Tools</h2>
            <p className="section-description">
                Useful tools for developers and programmers to streamline their workflow.
            </p>
            <div className="tools-grid">
                <GuidGenerator />
            </div>
        </section>
    );
};

export default ProgrammerSection; 