import React from 'react';

interface TabNavigationProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ activeSection, setActiveSection }) => {
    return (
        <nav className="tabs">
            <button
                className={activeSection === 'converter' ? 'active' : ''}
                onClick={() => setActiveSection('converter')}
            >
                Converter
            </button>
            <button
                className={activeSection === 'programmer' ? 'active' : ''}
                onClick={() => setActiveSection('programmer')}
            >
                Programmer
            </button>
            {/* <button
                className={activeSection === 'translate' ? 'active' : ''}
                onClick={() => setActiveSection('translate')}
            >
                Translation & Transcription
            </button> */}
        </nav>
    );
};

export default TabNavigation; 