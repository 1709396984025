import React from 'react';

const TranslateSection: React.FC = () => {
    return (
        <section className="section-content">
            <h2>Translation & Transcription Tools</h2>
            <p className="section-description">
                Translate text between languages or transcribe audio and video content.
            </p>
            <div className="tools-grid">
                <div className="tool-card">
                    <h3>Text Translation</h3>
                    <div className="tool-content">
                        <div className="translation-container">
                            <div className="translation-column">
                                <select>
                                    <option>English</option>
                                    <option>Turkish</option>
                                    <option>German</option>
                                    <option>French</option>
                                    <option>Spanish</option>
                                </select>
                                <textarea placeholder="Enter text to translate..."></textarea>
                            </div>
                            <div className="translation-column">
                                <select>
                                    <option>Turkish</option>
                                    <option>English</option>
                                    <option>German</option>
                                    <option>French</option>
                                    <option>Spanish</option>
                                </select>
                                <textarea placeholder="Translation will appear here..." readOnly></textarea>
                            </div>
                        </div>
                        <button className="action-button">Translate</button>
                    </div>
                </div>

                <div className="tool-card full-width">
                    <h3>Video Transcription</h3>
                    <div className="tool-content">
                        <div className="upload-container">
                            <div className="upload-area">
                                <p>Drag and drop a video file here or</p>
                                <button className="action-button">Choose File</button>
                            </div>
                        </div>
                        <div className="transcription-result">
                            <textarea placeholder="Transcription will appear here..." readOnly></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TranslateSection; 