import React, { useEffect } from 'react';

interface AdBannerProps {
    position: 'left' | 'right';
    adSlot: string;
}

// Google AdSense için window nesnesini genişlet
declare global {
    interface Window {
        adsbygoogle: any[];
    }
}

const AdBanner: React.FC<AdBannerProps> = ({ position, adSlot }) => {
    useEffect(() => {
        // Google AdSense kodunu yükle
        const loadAds = () => {
            try {
                if (window.adsbygoogle) {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                }
            } catch (error) {
                console.error('Ad loading error:', error);
            }
        };

        // Komponent mount edildiğinde reklamları yükle
        loadAds();
    }, []);

    return (
        <div className={`ad-banner ad-banner-${position}`}>
            <div className="ad-label">Advertisement</div>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-XXXXXXXXXXXXXXXX" // Gerçek Google AdSense ID'nizi buraya ekleyin
                data-ad-slot={adSlot}
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdBanner; 