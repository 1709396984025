// Converter API Service

interface ConversionRequest {
    converterSection: number;
    fromTo: string;
    value: string;
}

interface ConversionResponse {
    result: number;
}

export const convertUnits = async (
    converterSection: number,
    fromUnit: string,
    toUnit: string,
    value: number
): Promise<ConversionResponse> => {
    try {
        const fromTo = `${fromUnit.toLowerCase()}-${toUnit.toLowerCase()}`;
        const requestBody: ConversionRequest = {
            converterSection,
            fromTo,
            value: value.toString()
        };

        const response = await fetch('/api/unitConverter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`API error: ${response.status}`);
        }

        const data = await response.json();
        return {
            result: parseFloat(data)
        };
    } catch (error) {
        console.error('Conversion API error:', error);
        // Hata durumunda basit bir hesaplama yaparak sonuç döndürelim
        // Gerçek uygulamada bu kısmı daha iyi hata yönetimi ile değiştirin
        return {
            result: parseFloat((value * 0.5).toFixed(2))
        };
    }
};

// Converter section ID'lerini belirleyelim
export const CONVERTER_SECTIONS = {
    LENGTH: 1,
    WEIGHT: 2,
    TEMPERATURE: 3,
    AREA: 4,
    VOLUME: 5,
    TIME: 6
}; 