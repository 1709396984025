import React from 'react';

const Header: React.FC = () => {
    return (
        <header>
            <h1>Online Tools Repository</h1>
            <p className="subtitle">Collection of Useful Tools</p>
        </header>
    );
};

export default Header; 